<template>
    <div class="d-flex flex-column flex-grow-1">
        <svg-sprite/>
        <top-menu :is-dashboard="true"/>
        <admin-drawer-menu :mini="mini" class="page__drawer-menu"/>
        <v-main class="page__main main page__main--dashboard">
            <slot> <!--CONTENT--> </slot>
        </v-main>
    </div>
</template>

<script>
import SvgSprite from "@/components/SvgSprite";
import TopMenu from "@/components/TopMenu";
import AdminDrawerMenu from "@/components/admin/AdminDrawerMenu";

export default {
    name: "admin-dashboard-layout",
    components: {SvgSprite, TopMenu, AdminDrawerMenu},

    data: function () {
        return {
            mini: this.$root.mini
        }
    },
}
</script>

<style scoped>

</style>
