<template>
    <component :is="layout">
        <slot></slot>
    </component>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import VenueDashboardLayout from "@/layouts/VenueDashboardLayout";
import ArtistDashboardLayout from "@/layouts/ArtistDashboardLayout";
import UserDashboardLayout from "@/layouts/UserDashboardLayout";

export default {
    name: "DashboardLayout",
    data() {
        return {
            user: this.$auth.user()
        }
    },
    computed: {
        layout() {
            const roles = this.user.roles

            if (roles.includes('admin')) {
                return AdminDashboardLayout
            }

            if (roles.includes('venue')) {
                return VenueDashboardLayout
            }

            if (roles.includes('artist')) {
                return ArtistDashboardLayout
            }

            return UserDashboardLayout
        }
    }
}
</script>

<style scoped>

</style>
