<template>
    <div class="d-flex flex-column flex-grow-1">
        <svg-sprite/>
        <top-menu :is-dashboard="true"/>
        <venue-drawer-menu :mini="mini" class="page__drawer-menu"/>
        <v-main class="page__main main page__main--dashboard">
            <v-container class="fill-height align-content-start">
                <slot> <!--CONTENT--> </slot>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import SvgSprite from "../components/SvgSprite";
import TopMenu from "../components/TopMenu";
import VenueDrawerMenu from "@/components/venue/VenueDrawerMenu";


export default {
    name: "artist-dashboard-layout",
    components: {SvgSprite, TopMenu, VenueDrawerMenu},
    data: function () {
        return {
            mini: this.$root.mini
        }
    },
}
</script>

<style scoped>

</style>
