export default [
    {
        to: {name: 'artist.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'dashboard.chats'},
        icon: 'mdi-message-text',
        text: 'Chats',
    },
    {
        to: {name: 'dashboard.favourites'},
        icon: 'mdi-heart',
        text: 'Favorites',
    },
    {
        to: {name: 'dashboard.billing'},
        icon: 'mdi-credit-card',
        text: 'Billing',
    },
    {
        to: {name: 'dashboard.billing.select-plan'},
        icon: 'mdi-credit-card',
        text: 'Select Plan',
    },
    {
        to: {name: 'artist.tracks.index'},
        icon: 'mdi-playlist-music',
        text: 'Tracks',
    },
    {
        to: {name: 'artist.videos.index'},
        icon: 'mdi-video',
        text: 'Videos',
    },
    {
        to: {name: 'artist.gigs.index'},
        icon: 'mdi-calendar',
        text: 'Gigs',
    },
    {
        to: {name: 'artist.equipment.sync'},
        icon: 'mdi-speaker',
        text: 'Equipment',
    },
    {
        to: {name: 'artist.instruments.sync'},
        icon: 'mdi-guitar-acoustic',
        text: 'Instruments',
    },
]
