export default [
    {
        to: {name: 'venue.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'dashboard.chats'},
        icon: 'mdi-message-text',
        text: 'Chats',
    },
    {
        to: {name: 'dashboard.favourites'},
        icon: 'mdi-heart',
        text: 'Favorites',
    },
    {
        to: {name: 'dashboard.billing'},
        icon: 'mdi-credit-card',
        text: 'Billing',
    },
    {
        to: {name: 'dashboard.billing.select-plan'},
        icon: 'mdi-credit-card',
        text: 'Select Plan',
    },
    {
        to: {name: 'venue.gigs.index'},
        icon: 'mdi-calendar',
        text: 'Gigs',
    },
    {
        to: {name: 'venue.menus.index'},
        icon: 'mdi-silverware',
        text: 'Menus',
    },
    {
        to: {name: 'venue.venue-features.sync'},
        icon: 'mdi-paw',
        text: 'Venue Features',
    },
    {
        to: {name: 'venue.food-types.sync'},
        icon: 'mdi-food',
        text: 'Food Types',
    },
]
