export default [
    {
        to: {name: 'dashboard.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'dashboard.chats'},
        icon: 'mdi-message-text',
        text: 'Chats',
    },
    {
        to: {name: 'dashboard.favourites'},
        icon: 'mdi-heart',
        text: 'Favorites',
    },
]
