<template>
    <div class="d-flex flex-column flex-grow-1">
        <svg-sprite />
        <top-menu :is-dashboard="true" />
        <user-drawer-menu :mini="mini" class="page__drawer-menu" />
        <v-main class="page__main main page__main--dashboard">
            <v-container>
                <slot> <!--CONTENT--> </slot>
            </v-container>
        </v-main>
    </div>
</template>

<script>
    import SvgSprite from "../components/SvgSprite";
    import TopMenu from "../components/TopMenu";
    import UserDrawerMenu from "../components/dashboard/UserDrawerMenu";

    export default {
        name: "user-dashboard-layout",
        components: {SvgSprite, TopMenu, UserDrawerMenu},
        data: function () {
            return {
                mini: this.$root.mini,
            }
        },
    }
</script>

<style scoped>

</style>
